import React, { RefObject } from 'react'
import Slider, { ResponsiveObject, Settings } from 'react-slick'
import { AsH1, TitleLG } from '@/atoms/Text'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { PortraitTileCard } from '@/molecules/PortraitRowCollection/PortraitTileCard'
import { LinkViewModel } from '@/services/RenderService'

interface Props extends React.HTMLAttributes<HTMLElement> {
  contentLinks?: LinkViewModel[]
  title?: string
  testId?: string
  children?: React.ReactNode
  responsiveSlick?: ResponsiveObject[]
  slidesToShow?: number
  slidesToScroll?: number
  arrows?: boolean
  slickRef?: RefObject<Slider>
  beforeChange?: Settings['beforeChange']
  haltLazyLoad?: boolean
}

const defaultResponsiveSlick: ResponsiveObject[] = [
  {
    breakpoint: 1536,
    settings: {
      slidesToScroll: 6,
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
]

export const PortraitRowCollection: React.FC<Props> = ({
  contentLinks,
  title,
  testId = 'portrait-row-collection',
  children,
  responsiveSlick = defaultResponsiveSlick,
  slidesToShow = 8,
  slidesToScroll = 8,
  arrows = true,
  slickRef,
  beforeChange,
  haltLazyLoad = false,
  ...rest
}) => {
  return (
    <div data-testid={testId} {...rest}>
      {title && (
        <header>
          <TitleLG weight="bold" as={AsH1} className="mb-3 md:mb-4">
            {title}
          </TitleLG>
        </header>
      )}

      <HorizontalItemScroller
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        responsive={responsiveSlick}
        arrows={arrows}
        slickRef={slickRef}
        beforeChange={beforeChange}
        haltLazyLoad={haltLazyLoad}
      >
        {children && !contentLinks ? children : null}
        {!children && contentLinks
          ? contentLinks?.map((contentLink) => {
              return (
                <PortraitTileCard
                  className="mb-2 mr-4 max-h-full overflow-hidden"
                  key={contentLink.imageUrl}
                  linkModel={contentLink}
                />
              )
            })
          : null}
      </HorizontalItemScroller>
    </div>
  )
}
